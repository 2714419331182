<template>
  <div>
    <app-breadcrumb
      action="Tambah Poster Baru"
      link="/kabar/kabar-tambah"
    />
    <b-card>
      <div class="d-flex justify-content-end">
        <b-button-group>
          <b-button
            :variant="toggleKabar ? 'outline-primary' : 'primary'"
            @click="toggleKabar=false"
          >
            Customer
          </b-button>
          <b-button
            :variant="toggleKabar ? 'primary' : 'outline-primary'"
            @click="toggleKabar=true"
          >
            Mitra
          </b-button>
        </b-button-group>
      </div>
      <br>
      <b-row class="match-height">
        <b-col
          v-for="news in itemsKabar"
          :key="news.komen"
          cols="12"
          md="6"
          lg="6"
        >
          <div
            v-if="!toggleKabar"
            class="shadow-lg bg-body rounded"
          >
            <b-card no-body>
              <b-card-body class="p-2">
                <b-card-title size="sm">
                  {{ news.title }}
                </b-card-title>
                <b-card-sub-title>
                  {{ news.subTitle }} • {{ news.kategori }}
                </b-card-sub-title>
              </b-card-body>
              <b-img
                fluid
                :src="news.urlThumbnail"
                alt="Card image cap"
              />
              <b-card-body>
                <b-card-text>
                  {{ news.desc }}
                </b-card-text>
                <br>
                <b-button
                  variant="danger"
                  class="card-link"
                  @click="hapusKabar(news.id)"
                >
                  Hapus Kabar ini
                </b-button>
              </b-card-body>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <list-mitra
        v-if="toggleKabar"
      />
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BImg,
  BButton,
  BButtonGroup,
} from 'bootstrap-vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import Ripple from 'vue-ripple-directive'
import { db } from '@/firebase'
import ListMitra from './ListMitra.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AppBreadcrumb,
    BImg,
    BButton,
    BButtonGroup,
    ListMitra,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemsKabar: [],
      itemsKabarMitra: [],
      toggleKabar: false,
    }
  },
  created() {
    this.getKabar()
  },
  methods: {
    getKabar() {
      db.collection('appConfig').doc('mobile_customer').collection('kabarMecare')
        .get()
        .then(snapshot => {
          const arr = []
          snapshot.docs.forEach(doc => {
            arr.push({
              id: doc.id,
              title: doc.data().title,
              urlThumbnail: doc.data().url_thumbnail,
              subTitle: doc.data().sub_title,
              desc: doc.data().desc,
              kategori: doc.data().kategori,
            })
          })
          this.itemsKabar = [...new Set(arr)]
        })
    },
    hapusKabar(id) {
      this.$swal({
        title: 'Hapus kabar mecare?',
        text: 'konfirmasi jika anda ingin menghapus data kabar',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('appConfig').doc('mobile_customer').collection('kabarMecare').doc(id)
            .delete()
            .then(() => {
              this.getKabar()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menghapus data kabar',
                  icon: 'TrashIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
